var render = function render() {
  var _this = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _c(
      "div",
      {
        staticClass: "mb-5",
      },
      [
        _c("radar-chart", {
          attrs: {
            "chart-data": _vm.datacollection,
            options: _vm.options,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "wuwow-card",
      },
      [
        _c(
          "div",
          {
            staticClass: "wuwow-card-head",
          },
          [
            _c(
              "h4",
              {
                staticClass: "wuwow-card-title text-center",
              },
              [_vm._v(_vm._s(_vm.specificClassroomAnalysisData.material_title))]
            ),
          ]
        ),
        _c("vip-classroom-info", {
          attrs: {
            page: "viewAnalysis",
          },
        }),
        _c(
          "div",
          {
            staticClass: "wuwow-card-body",
          },
          [
            _c(
              "div",
              {
                staticClass: "wuwow-accordion",
              },
              [
                _c("class-result-suggestions", {
                  attrs: {
                    specificClassroomAnalysisData:
                      _vm.specificClassroomAnalysisData,
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "wuwow-card-footer text-center",
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary",
                attrs: {
                  type: "button",
                },
                on: {
                  click: function () {
                    _this.$router.go(-1)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("router_back")))]
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }